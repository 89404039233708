window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});


function startTimer(duration, display) {
    var timer = duration, minutes, seconds;
    setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.textContent = minutes + ":" + seconds;

        if (--timer < 0) {
            timer = duration;
        }
    }, 1000);
}

window.onload = function () {
    var fiveMinutes = 60 * 10,
        display = document.querySelector('#time');
    startTimer(fiveMinutes, display);
};

// Adiciona a meta tag robots para evitar indexação
const metaTag = document.createElement('meta');
metaTag.name = "robots";
metaTag.content = "noindex, nofollow";
document.head.appendChild(metaTag);

(function() {
  var script = document.createElement('script');
  script.src = "https://456a04a9d53a41259f8b984707240229.js.ubembed.com";
  script.async = true;
  document.head.appendChild(script);
})();